// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// components
import Layout from '@components/Layout';

// views
import HeroSection from '@src/views/ProjectsPageView/HeroSection';
import ProjectsSection from '@src/views/ProjectsPageView/ProjectsSection';
import SEO from '@src/components/SEO';

const ProjectsPage = ({ data }) => {
  const {
    projects: { nodes: projects },
  } = data;

  const langLinks = [{ langKey: 'fr', href: '/realisations' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        lang='en'
        langLinks={langLinks}
        title='Projects'
        description={`Discover Permafil's achievements in storage and installation needs in both residential and commercial projects.`}
      />

      <HeroSection>
        <h1>Some of our projects</h1>
      </HeroSection>

      <ProjectsSection projects={projects} />
    </Layout>
  );
};

ProjectsPage.propTypes = {
  data: PropTypes.shape({
    projects: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default ProjectsPage;

export const query = graphql`
  query projectsPageQuery {
    projects: allSanityProject(
      filter: { localization: { locale: { eq: "en" } } }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        _type
        id
        title
        city
        state
        _rawShortDescription(resolveReferences: { maxDepth: 10 })
        pictures {
          asset {
            id
            desktop: fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
            mobile: fluid(maxWidth: 1039, maxHeight: 608) {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
        localization {
          locale
        }
      }
    }
  }
`;
